<template>
  <the-layout-view-content class="content">
    <BaseH1 :text="'Witamy'" :short="true" />

    <TextDescription
      v-html="'Dołącz do nas, <br />aby kontaktować z nianiami.'"
    />

    <div class="wrapper">
      <ButtonCta
        :to="ROUTE_REGISTRATION_IDENTIFICATION"
        :label="'Założ konto'"
      />
      <ButtonLink :to="{ name: 'Login' }" :label="'Zaloguj się'" />
    </div>
    <NavMobileBack v-if="isMobile" />
  </the-layout-view-content>
</template>

<script>
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import NavMobileBack from "@/components/NavMobileBack.vue";
import useIsMobile from "@/composables/useIsMobile";
import { ROUTE_REGISTRATION_IDENTIFICATION_NAME } from "@/router/constants";

export default {
  components: {
    BaseH1,
    TextDescription,
    ButtonCta,
    ButtonLink,
    NavMobileBack,
  },

  setup() {
    const { isMobile } = useIsMobile();
    const ROUTE_REGISTRATION_IDENTIFICATION = {
      to: ROUTE_REGISTRATION_IDENTIFICATION_NAME,
    };

    return {
      isMobile,
      ROUTE_REGISTRATION_IDENTIFICATION,
    };
  },
};
</script>

<style scoped>
.content {
  row-gap: 80px;
}
.wrapper {
  display: flex;
  flex-flow: column;
  row-gap: 30px;
  align-items: center;
}
</style>
